import { gql } from '@apollo/client';

import { NAVIGATION_FRAGMENT } from './navigation';

export const FEATURE_BLOCKS_FRAGMENT = gql`
  ${NAVIGATION_FRAGMENT}
  fragment FeatureFigureModule on SanityFeatureFigureVideoGuide {
    ... on SanityFeatureFigureVideoGuide {
      _key
      _type
      video
    }
  }
  fragment FeatureModule on SanityFeatureModule {
    _key
    title
    description
    figureModule {
      ...FeatureFigureModule
    }
    hideFromApps
  }
  fragment MultiFeatureModule on SanityMultiFeatureModule {
    _key
    title
    featureModules {
      _key
      title
      description
      figureModule {
        ...FeatureFigureModule
      }
    }
  }
  fragment FeatureModuleTripDuration on SanityFeatureModuleTripDuration {
    _key
    title
    description
  }
  fragment FeatureVideo on SanityFeatureVideo {
    _key
    title
    videoUrl
    autoplay
    poster1x1: poster(options: { width: 1000, height: 1000, quality: 75 }) {
      url
    }
    poster16x9: poster(options: { width: 1600, height: 900, quality: 75 }) {
      url
    }
  }
  fragment FeatureImage on SanityFeatureImage {
    _key
    blockName
    alt
    image(options: { width: 2000, quality: 100 }) {
      url
      metadata {
        dimensions {
          width
          height
        }
      }
    }
    variant
    hideFromApps
    link {
      _key
      page {
        _id
        _type
        slug {
          current
        }
      }
      externalLink
      showIntercom
    }
  }
  fragment HowItWorksFeatureBlock on SanityHowItWorksFeatureBlock {
    _key
    steps {
      _key
      title
      description
    }
    demonstrationVideo
  }
  fragment StationMapFeatureBlock on SanityStationMapFeatureBlock {
    _key
    description
    variant
    hideFromApps
  }
  fragment TripStatsFeatureBlock on SanityTripStatsFeatureBlock {
    _key
    variant
    hideFromApps
    phraseGroups {
      _key
      phrases
      threshold
    }
  }
  fragment PageFeatureBlock on SanityPageFeatureBlock {
    _key
    title
    pageRefType
    variant
    hideFromApps
  }
  fragment InfoBlock on SanityInfoBlock {
    _key
    title
    description
    emotion
    variant
    hideFromApps
    link {
      ...Navigation
    }
  }
  fragment FeatureBlocks on SanityFeatureBlock {
    ... on SanityFeatureModule {
      ...FeatureModule
    }
    ... on SanityMultiFeatureModule {
      ...MultiFeatureModule
    }
    ... on SanityFeatureModuleTripDuration {
      ...FeatureModuleTripDuration
    }
    ... on SanityFeatureVideo {
      ...FeatureVideo
    }
    ... on SanityFeatureImage {
      ...FeatureImage
    }
    ... on SanityHowItWorksFeatureBlock {
      ...HowItWorksFeatureBlock
    }
    ... on SanityStationMapFeatureBlock {
      ...StationMapFeatureBlock
    }
    ... on SanityTripStatsFeatureBlock {
      ...TripStatsFeatureBlock
    }
    ... on SanityPageFeatureBlock {
      ...PageFeatureBlock
    }
    ... on SanityInfoBlock {
      ...InfoBlock
    }
  }
`;
